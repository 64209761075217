import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    meta: { requiresAuth: false },
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/callback',
    name: 'CallbackView',
    meta: { requiresAuth: false },
    component: () => import('../views/CallbackView.vue')
  },
  {
    path: '/acceptterms',
    name: 'AcceptTermsView',
    meta: { requiresAuth: true },
    component: () => import('../views/AcceptTermsView.vue')
  },
  {
    path: '/course',
    name: 'CourseView',
    meta: { requiresAuth: true, requiresUserInformation: true  },
    component: () => import('../views/CourseView.vue')
  },
  {
    path: '/review',
    name: 'ResultView',
    meta: { requiresAuth: true },
    component: () => import('../views/ResultView.vue')
  },
  {
    path: '/information',
    name: 'InformationView',
    meta: { requiresAuth: true },
    component: () => import('../views/InformationView.vue')
  },
  {
    path: '/startcourse',
    name: 'StartCourseView',
    meta: { requiresAuth: true, requiresUserInformation: true },
    component: () => import('../views/StartCourseView.vue')
  },
  {
    path: '/courseend',
    name: 'CourseEndedView',
    meta: { requiresAuth: true },
    component: () => import('../views/CourseEndedView.vue')
  }
]

const router = createRouter({ history: createWebHistory(), routes })

router.beforeEach((to, from, next) => {
  if(to.meta.requiresUserInformation && (!localStorage.getItem('zipCode') || !localStorage.getItem('city') || !localStorage.getItem('address') 
    || !localStorage.getItem('fullName') || !localStorage.getItem('phoneNumber') || !localStorage.getItem('email'))) {
    next({ name: 'InformationView' })
  } else if ((to.meta.requiresAuth && !localStorage.getItem('criiptoIdentifier'))) {
    next({ name: 'HomeView' })
  } else {
    next()
  }
})

export default router
