import { img as betongkakelkeramik } from '@/store/tempimages/betongkakelkeramik'
import { img as hardplast } from '@/store/tempimages/hardplast'
import { img as energiatervinning } from '@/store/tempimages/energiatervinning'
import { img as metall } from '@/store/tempimages/metall'
import { img as tradgardsavfall } from '@/store/tempimages/tradgardsavfall'
import { img as wellpapp } from '@/store/tempimages/wellpapp'
import { img as gips } from '@/store/tempimages/gips'
import { img as risochgrenar } from '@/store/tempimages/risochgrenar'
import { img as lastpallar } from '@/store/tempimages/lastpallar'
import { img as dack } from '@/store/tempimages/dack'
import { img as bocker } from '@/store/tempimages/bocker'
import { img as elavfall } from '@/store/tempimages/elavfall'
import { img as farligtavfall } from '@/store/tempimages/farligtavfall'
import { img as geogtabyggmaterial } from '@/store/tempimages/geogtabyggmaterial'
import { img as tra } from '@/store/tempimages/tra'
import { img as kolfrys } from '@/store/tempimages/kolfrys'
import { img as batterier } from '@/store/tempimages/batterier'
import { img as klader } from '@/store/tempimages/klader'
import { img as rest } from '@/store/tempimages/rest'
import { img as forpack } from '@/store/tempimages/forpack'
import { img as inpasseringutpassering } from '@/store/tempimages/inpasseringutpassering'
import { img as sorteringsguide } from '@/store/tempimages/sorteringsguide'

export default {
  state: {
    pages1: [
      {
        prio: 1,
        title: 'Välkommen',
        content:
          '<p>För att registrera dig och genomföra utbildningen för obemannad returpunkt behöver du logga in med BankID.</p>' +
          '<p>Genom att logga in med BankID godkänner du att vi behandlar dina personuppgifter med syfte att hantera ditt tillträde till obemannad returpunkt.</p>' +
          '<p>Behandling av personuppgifter <a href="https://nodra.se/om-oss/om-nodra.se/behandling-av-personuppgifter" target="_blank">(nodra.se)</a></p>',
        nextButtonText: 'Logga in med BankID'
      }
    ],
    pages2: [
      {
        prio: 1,
        title: 'Utbildning för obemannad returpunkt',
        content:
          '<b>Det här är en digital utbildning för dig som blivit erbjuden att delta i pilotprojektet obemannad returpunkt på Returpunkt Krokek. Utbildningen består av:</b>' +
          '<ul><li>regler och villkor</li><li>information om sortering</li><li>ett kunskapstest</li></ul>' +
          '<p>Hela utbildningen tar cirka 20 minuter att genomföra.</p>' +
          '<p>När du klarat testet är du godkänd och kan börja använda dig av obemannad returpunkt.</p>' +
          '<p>Mer information om öppettider och Returpunkt Krokek finns på vår webbplats nodra.se. Där hittar du också samma information om villkor och hur obemannad returpunkt fungerar.</p>' +
          '<a href="https://nodra.se/obemannad-returpunkt" target="_blank">Obemannad returpunkt</a>' +
          '<h2>Såhär fungerar pilotprojektet</h2>' +
          '<p>Obemannad returpunkt är ett pilotprojekt där vi testar konceptet på Returpunkt Krokek. Det är endast du som bor i Krokek med omnejd, eller äger en fastighet i området som har möjlighet att vara med i piloten.</p>' +
          '<p>Pilotprojektet pågår under tre månader, men kan komma att förlängas. </p>' +
          '<p>I och med att detta är ett nytt koncept som vi testar kan det förekomma förändringar under pilotens gång, till exempel vilka avfallsslag som du kan lämna och vilka obemannade tider som du kan besöka returpunkten på.</p>' +
          '<p>Notera att detta är en tillfälligt pilot. Det innebär att vi efter pilotperioden kan komma att stänga möjligheten att besöka returpunkten under obemannade tider för att utvärdera piloten innan en eventuell fortsättning.</p>' +
          '<p>Vi kommer att meddela alla eventuella förändringar eller driftstörningar till den e-postadress du uppger vid registreringen. Håll därför utkik där för information från oss.</p>' +
          '<p>Du kommer få svara på en utvärdering efter pilotperiodens slut.</p>'
      }
    ],
    pages3: [
      {
        prio: 1,
        title: 'Villkor och regler',
        content:
          '<b>För att få tillträde till den obemannade returpunkten behöver du godkänna våra villkor.</b>' +
          '<h2>Tillträde</h2>' +
          '<p>Obemannad returpunkt gäller enbart dig som besöker returpunkten som privatperson.</p>' +
          '<p>Du som besöker returpunkten med en företagsregistrerad lastbil kommer att faktureras för ditt besök automatiskt, precis som vid ordinarie öppettider.</p>' +
          '<p>Du får självklart ha med dig någon som hjälper till att bära/slänga ditt avfall, men det är du som ansvarar för att villkoren följs av alla i ditt sällskap.</p>' +
          '<p>Du måste ha Nodras app och BankID med dig för att kunna komma in på området under obemannade tider.</p>' +
          '<h2>Sortering</h2>' +
          '<p>Du behöver sortera ditt avfall enligt den här utbildningen och sorteringsanvisningar på plats. Ta gärna hjälp av sorteringsguiden i Nodras app.</p>' +
          '<p>Vid upprepad felsortering eller dumpning kan du komma att förlora behörigheten att besöka obemannad returpunkt.</p>' +
          '<p>Vi kan tyvärr inte erbjuda rullar med matavfallspåsar på obemannad returpunkt. Dessa får du hämta under bemannad tid.</p>' +
          '<h2>Personuppgifter</h2>' +
          '<p>Alla dina besök på obemannad returpunkt registreras och lagras tillsammans med dina personuppgifter. Vi behandlar personuppgifter enligt den nya Dataskyddsförordningen (GDPR).</p>' +
          '<h2>Säkerhet</h2>' +
          '<p>Vi är rädda om dig! Följ våra säkerhetsregler, särskilt när du besöker oss under obemannade tider.</p>' +
          '<p>Hastighetsbegränsningen är 20 km/h på hela området.</p>' +
          '<p>Om något allvarligt inträffar, en olycka eller brand, ring 112. Det finns brandsläckare och första hjälpen-kit på gästtoaletten på kortsidan av huset på returpunkten.</p>' +
          '<p>Viktig information för akuta problem inne på området finns på personalhuset.</p>' +
          '<p>En nödutgång finns till höger om den stora grinden. Den är öppen för utpassage men stängd för inpassage. </p>' +
          '<p>Klättra aldrig ner i en container! Kontakta Nodra om du råkat lägga något fel eller om du tappat något. </p>' +
          '<p>Vintertid kan det vara halt. Var försiktig så att du inte halkar.</p>' +
          '<p>Om det är väldigt dåligt väder, eller om vi inte hunnit ploga eller sanda området stänger vi obemannad returpunkt.</p>' +
          '<h2>Trivsel</h2>' +
          '<p>Vi har sopborstar på rampen. Tack för att du städar efter dig om det behövs. </p>' +
          '<p>Under obemannade tider vill vi att du kontaktar oss på Nodra om en container är full eller vid andra problem som inte är akuta. Felanmälan gör du i appen.</p>',
        nextButtonText: 'Jag godkänner villkoren'
      },
      {
        prio: 2,
        title: 'Inpassering och utpassering',
        content:
          '<b>Du använder Nodras app för att komma in på returpunkten. Appen finns att ladda ner i Google Play eller App Store.</b>' +
          '<img src="' +
          inpasseringutpassering +
          '" />' +
          '<h2>Inpassering</h2>' +
          '<p>Du använder Nodras app och BankID för att komma in på returpunkten. Appen använder platstjänster för att öppna grinden vilket innebär att du måste godkänna platstjänster för vår app.</p>' +
          '<p>Har du problem med att öppna grinden med appen ber vi dig att rapportera det på sidan om Nodras app. Tyvärr kan vi inte hjälpa dig direkt och ber dig att besöka oss under bemannade tider tills ditt problem med appen är löst.</p>' +
          '<p>Det är viktigt att du själv öppnar grinden innan du åker in på returpunkten. Om någon annan har åkt in på området före dig ska du vänta tills grinden är stängd igen innan du öppnar grinden via appen.</p>' +
          '<p>När du kört genom grinden stängs den automatiskt efter att du har passerat den markerade linjen i marken. Fler instruktioner för hur du öppnar grinden hittar du i appen.</p>' +
          '<h2>Utpassering</h2>' +
          '<p>För att köra ut från området kör du över den markerade linjen på marken framför grinden. Då öppnas grinden automatiskt.</p>' +
          '<p>Öppnas inte grinden automatiskt när du ska köra ut? Följ instruktionerna som finns på insidan av grinden. När du har kommit ut, vänta gärna tills grinden stängs bakom dig innan du kör iväg.</p>' +
          '<a href="https://nodra.se/sjalvservice-och-kontakt/sjalvservice-ovrigt/nodras-app" target="_blank">Nodras app (nodra.se)</a>'
      },
      {
        prio: 3,
        title: 'Sortering',
        content:
          '<b>Det är viktigt att du sorterar ditt avfall så att det i nästa steg i processen kan behandlas på ett hållbart sätt.</b>' +
          '<img src="' +
          sorteringsguide +
          '" />' +
          '<h2>Sortera rätt på returpunkten</h2>' +
          '<p>Ett tips för att få ett smidigt besök är att du har koll på vad du ska slänga och hur det ska sorteras, redan innan ditt besök.</p>' +
          '<p>Ta gärna en titt på våra områdeskartor för att veta var du ska lämna de olika avfallsslagen.</p>' +
          '<p>Om du är osäker på var avfallet ska slängas är det bättre att du besöker returpunkten under våra bemannade öppettider.</p>' +
          '<p>Områdeskartorna hittar du på nodra.se</p>' +
          '<a href="https://nodra.se/avfall-och-atervinning/returpunkter" target="_blank">Returpunkter (nodra.se)</a>' +
          '<p>Grinden stängs automatiskt efter att du har passerat den markerade linjen på marken. Grinden kommer inte att stängas så länge det står en person eller ett fordon i grindöppningen.</p>' +
          '<h2>Nodras sorteringsguide</h2>' +
          '<p>I vår sorteringsguide kan du få hjälp med hur du ska sortera ditt avfall. Sök på en artikel för att se vilken kategori den ska slängas som.</p>' +
          '<p>Sorteringsguiden finns på nodra.se och i Nodras app.</p>' +
          '<a href="https://nodra.se/sorteringsguide" target="_blank">Sorteringsguide (nodra.se)</a>'
      },
      {
        prio: 4,
        title: 'Avfallsslag',
        content:
          '<b>På våra returpunkter kan du lämna följande avfallsslag.</b>' +
          '<p>I den här utbildningen kommer du få lära dig vad du kan lämna för olika avfallsslag på våra returpunkter och vad du ska tänka på när du sorterar ditt avfall.</p>' +
          '<ul>' +
          '<li>Betong, kakel & keramik</li>' +
          '<li>Hårdplast</li>' +
          '<li>Energiåtervinning</li>' +
          '<li>Trä & impregnerat trä</li>' +
          '<li>Metall</li>' +
          '<li>Trädgårdsavfall</li>' +
          '<li>Wellpapp</li>' +
          '<li>Gips</li>' +
          '<li>Ris & grenar</li>' +
          '<li>Lastpallar</li>' +
          '<li>Vitvaror, kyl & frys</li>' +
          '<li>Däck med & utan fälg</li>' +
          '<li>Böcker</li>' +
          '<li>Batterier & ljuskällor</li>' +
          '<li>Elavfall</li>' +
          '<li>Farligt avfall</li>' +
          '<li>Kläder och andra textilier</li>' +
          '<li>Ge & Ta byggmaterial</li>' +
          '<li>Rest efter sortering</li>' +
          '<li>Förpackningar & tidningar</li>' +
          '</ul>' +
          '<h2>Betong, kakel & keramik</h2>' +
          '<img src="' +
          betongkakelkeramik +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Betong, tegel, klinker, kakel och keramik.</li>' +
          '<li>Trasigt porslin, spegelglas, fönsterglas utan karm och dricksglas.</li>' +
          '<li>Isolering</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Gips ska du lämna i containern för gips. Badkar ska du lämna som metall.</li>' +
          '<li>Fönsterglas med karm lämnas som ”rest efter sortering”.</li>' +
          '<li>Inga säckar läggs i containern. Töm säcken och lägg den i rätt behållare.</li>' +
          '</ul>' +
          '<h2>Hårdplast</h2>' +
          '<img src="' +
          hardplast +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Plastmöbler, platsleksaker, hinkar, plastlådor.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Inte lägga färgburkar, slangar, glasfiber, mjukplast eller plasttak här. Om du är osäker på hur det ska sorteras är du välkommen till returpunkten under de bemannade tiderna när personalen kan hjälpa till.</li>' +
          '<li>Plastsaker som är en förpackning, så som ketchupflaskor, vindruvsförpackningar och tandkrämstuber, ska läggas bland plastförpackningar i återvinningsstationen eller i ditt fyrfackskärl.</li>' +
          '</ul>' +
          '<h2>Energiåtervinning</h2>' +
          '<img src="' +
          energiatervinning +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Mattor, stoppade möbler, tapeter, skumgummi, frigolit och invasiva växtarter.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Om du lämnar avfall i säck, använd gärna genomskinliga säckar. Det är för att allt du slänger ska vara synligt för personalen, både för att kunna se att det är rätt sorterat och för att det ska vara säkert att hantera.</li>' +
          '<li>Invasiva växter ska läggas i tätförslutna plastpåsar.</li>' +
          '</ul>' +
          '<h2>Trä & impregnerat trä</h2>' +
          '<img src="' +
          tra +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<p>Trä sorteras på flera sätt beroende på hur det är behandlat.</p>' +
          '<h4>Trä:</h4>' +
          '<ul>' +
          '<li>obehandlat trä, målat trä, spånskivor och trämöbler till exempel bord och stol.</li>' +
          '<li>Skruv, spik och beslag behöver du inte ta bort.</li>' +
          '</ul>' +
          '<h4>Tryckimpregnerat trä:</h4>' +
          '<ul>' +
          '<li>Till exempel trall, stolpar, staket och slipers.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Gipsskivor ska lämnas som Gips.</li>' +
          '</ul>' +
          '<h2>Metall</h2>' +
          '<img src="' +
          metall +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Sånt som består av mycket metall som badkar, stekpannor, plastjulgranar, grillar, diskbänkar, värmeljuskoppar och cyklar.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Allt med sladd och armatur lägger du i elektronik.</li>' +
          '<li>Tryckbehållare som gastuber och sprayflaskor räknas som farligt avfall. Ta med dem under bemannade tider.</li>' +
          '<li>Prylar med motor, till exempel gräsklippare, ska vara tömda på vätskor innan du kommer till returpunkten.</li>' +
          '</ul>' +
          '<h3>Visste du att</h3>' +
          '<ul>' +
          '<li>Metall är en råvara som kan återvinnas hur många gånger som helst utan att det tappar kvalitet.</li>' +
          '</ul>' +
          '<h2>Trädgårdsavfall</h2>' +
          '<img src="' +
          tradgardsavfall +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Gräsklipp, löv, mossa, fallfrukt, mindre kvistar.</li>' +
          '<li>Ej ris eller grenar.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Vissa växter, till exempel jättebalsamin, parkslide och lupin är invasiva. Det innebär att de sprids lätt med frön eller växtdelar och orsakar stor skada i naturen. Lämna invasiva växter i en genomskinlig säck i container för energiåtervinning.</li>' +
          '<li>Sågspån ska lämnas som energiåtervinning.</li>' +
          '<li>Lägg inga säckar i containern eftersom trädgårdsavfallet inte då går att göra till ny jord. Töm säcken och lägg den i rätt behållare.</li>' +
          '</ul>' +
          '<h2>Wellpapp</h2>' +
          '<img src="' +
          wellpapp +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Kartong och ren wellpapp, som är vågad/räfflad inuti.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Papprör och kartonger lämnar du som förpackningar i återvinningsstationen utanför returpunkten.</li>' +
          '</ul>' +
          '<h3>Visste du att</h3>' +
          '<ul>' +
          '<li>Wellpapp återvinns och kan bli ny wellpapp hela sju gånger!</li>' +
          '</ul>' +
          '<h2>Gips</h2>' +
          '<img src="' +
          gips +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Gipsskivor utan reglar och rena gipsföremål.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Frigolit och cellplast läggs i energiåtervinning.</li>' +
          '</ul>' +
          '<h2>Ris & grenar</h2>' +
          '<img src="' +
          risochgrenar +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Stammar, trädgrenar, buskar, julgranar.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Löv, häckklipp och mindre mängder jord ska lämnas vid trädgårdsavfall.</li>' +
          '</ul>' +
          '<h2>Lastpallar</h2>' +
          '<img src="' +
          lastpallar +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Pallkragar, EUR-pall, halvpall, engångspall.</li>' +
          '</ul>' +
          '<h3>Visste du att</h3>' +
          '<ul>' +
          '<li>Lastpallarna hämtas på returpunkterna och återanvänds precis som de är. En lastpall kan användas många gånger innan det är dags att skicka den till förbränning.</li>' +
          '</ul>' +
          '<h2>Vitvaror, kyl & frys</h2>' +
          '<img src="' +
          kolfrys +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Vitvaror som spisar, diskmaskiner och tvättmaskiner, spabad och varmvattenberedare, samt kyl och frys.</li>' +
          '<li>Vitvaror sorteras separat och kyl & frys sorteras separat.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Vitvaror som innehåller kylmedier ska lämnas stående, så att inte kylmedier läcker ut.</li>' +
          '<li>Mindre köksmaskiner som mikrovågsugn och dammsugare ska läggas i elavfall. Endast under bemannade tider.</li>' +
          '</ul>' +
          '<h2>Däck med & utan fälg</h2>' +
          '<img src="' +
          dack +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Däck med och utan fälg från personbil, moped och motorcykel.</li>' +
          '<li>Däck utan fälg får endast lämnas av privatpersoner. Max 8 däck per besök.</li>' +
          '<li>Däck med och utan fälg sorteras separat.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Cykeldäck sorteras som energiåtervinning eller restavfall.</li>' +
          '</ul>' +
          '<h2>Böcker</h2>' +
          '<img src="' +
          bocker +
          '" />' +
          '<p>Det absolut bästa är att inte slänga böcker överhuvudtaget. Lämna in dem till en second hand-butik, ett antikvariat, eller ge till vänner och bekanta. Många bibliotek, skolor och sjukhus tar också emot gamla böcker.</p>' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Böcker med hård eller mjuk pärm.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Inga påsar, tack!</li>' +
          '</ul>' +
          '<h2>Batterier & ljuskällor</h2>' +
          '<img src="' +
          batterier +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Småbatterier</li>' +
          '<li>Ljuskällor så som glödlampor, lysrör, halogenlampor, ledlampor, lågenergilampor</li>' +
          '<li>Lysrör</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Bilbatterier får du endast lämna under ordinarie öppettider när personal finns på plats.</li>' +
          '<li>Lysrör ska sorteras efter storlek. Kortare än 60 cm sorteras med övriga ljuskällor. Längre 60 cm har en egen plats.</li>' +
          '</ul>' +
          '<h2>Elavfall</h2>' +
          '<img src="' +
          elavfall +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Datorer, bildskärmar och telefoner </li>' +
          '<li>Fläktar </li>' +
          '<li>Köksmaskiner</li>' +
          '<li>Dammsugare</li>' +
          '<li>Leksaker med batteri</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Många apparater och leksaker i hemmet kan ha inbyggda batterier som inte alltid är så lätta att se. Även dessa ska sorteras som elavfall.</li><li>Elavfall kan endast lämnas under bemannade öppettider.</li>' +
          '</ul>' +
          '<h2>Farligt avfall</h2>' +
          '<img src="' +
          farligtavfall +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Kemikalier </li>' +
          '<li>Bekämpningsmedel och lösningsmedel</li>' +
          '<li>Färg</li>' +
          '<li>Spillolja</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Du ska aldrig lägga ditt farliga avfall i ditt sopkärl eller spola ner i avloppet. </li>' +
          '<li>Får endast lämnas av privatpersoner på returpunkten.</li>' +
          '<li>Kemikalier som är klassade som farligt avfall är alltid märkta med en faraosymbol. Symbolerna är vita med en röd ram.</li>' +
          '<li>Farligt avfall kan endast lämnas under bemannade öppettider.</li>' +
          '</ul>' +
          '<h2>Kläder och andra textilier</h2>' +
          '<img src="' +
          klader +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>all textil, till och med det som är trasigt, nopprigt eller urtvättat. </li>' +
          '<li>Skor och väskor.</li>' +
          '</ul>' +
          '<p>Myrorna kommer och tömmer våra textilboxar. Det som inte kan säljas vidare går till återvinning.</p>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>För att undvika att textilierna blir smutsiga, våta eller fastnar i luckan vill vi att du lägger dem i en påse eller emballerar dem på annat sätt innan du lägger textilierna i klädboxarna.</li>' +
          '</ul>' +
          '<h2>Ge & Ta byggmaterial</h2>' +
          '<img src="' +
          geogtabyggmaterial +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Byggmaterial som är i fint skick t.ex. hela fönster och dörrar, nytt eller fint virke, takpannor, verktyg, skruv och spik eller isolering i säck. </li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Vi tar inte emot elektriska verktyg eller farligt avfall.</li>' +
          '</ul>' +
          '<h2>Rest efter sortering</h2>' +
          '<img src="' +
          rest +
          '" />' +
          '<p>Rest efter sortering kallar vi den fraktion där du lämnar avfall som inte kan sorteras och lämnas någon annanstans på returpunkten. </p>' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Fönster med karm, vitrinskåp, möbler av blandat material.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Det som lämnas här sorteras i efterhand.</li>' +
          '<li>Inga säckar läggs i containern. Töm säcken och lägg den i rätt behållare.</li>' +
          '</ul>' +
          '<h2>Förpackningar & tidningar</h2>' +
          '<img src="' +
          forpack +
          '" />' +
          '<h3>Här ska du lämna</h3>' +
          '<ul>' +
          '<li>Förpackningar av papper, metall, plast eller ofärgat och färgat glas, till exempel mjölkförpackningar, äggkartonger, konservburkar och glasflaskor. </li>' +
          '<li>Här kan du också lämna tidningar och reklamblad.</li>' +
          '</ul>' +
          '<h3>Tänk på att</h3>' +
          '<ul>' +
          '<li>Dricksglas eller fönsterglas är ingen förpackning. Det lämnar du på returpunkten bland betong, kakel och keramik.</li>' +
          '<li>Kuvert, vykort och post it-lappar läggs i ditt vanliga restavfall.</li>' +
          '<li>Inbundna böcker lämnas i containern för böcker.</li>' +
          '<li>Häftklamrar, spiraler och dylikt behöver du inte ta bort från tidningar och block. De avskiljs med magneter i efterhand och skickar till metallåtervinning.</li>' +
          '</ul>' +
          '<h2>Avfall som inte går att lämna på obemannad returpunkt</h2>' +
          '<p>Vi har begränsningar i vad som går att lämna på obemannad returpunkt.</p>' +
          '<p>Välkommen att besöka returpunkten under de bemannade tiderna för att lämna följande:</p>' +
          '<ul>' +
          '<li>farligt avfall</li>' +
          '<li>elavfall</li>' +
          '</ul>' +
          '<p>Asbest kan du endast lämna på Returpunkt Norrköping i små mängder under ordinarie öppettider. Läs mer på nodra.se.</p>'
      },
      {
        prio: 5,
        title: 'Kunskapstest',
        content:
          '<b>Nu följer ett kort test innan du blir godkänd/behörig att använda obemannad returpunkt.</b>' +
          '<p>Testet består av 10 frågor där du får tre svarsalternativ på varje fråga. I slutet av testet får du veta hur många rätta svar du har fått och de rätta svarsalternativen till alla frågor. Om du har svarat fel på någon fråga får du börja om igen från början. Du måste svara rätt på alla frågor för att bli godkänd. </p>',
        nextButtonText: 'Gå till kunskapstest'
      }
    ],
    pages4: [
      {
        prio: 1,
        title: 'Välkommen att besöka obemannad returpunkt!',
        content:
          '<p>Du har nu klarat utbildningen och har fått behörighet att besöka obemannad returpunkt.</p>' +
          '<p>Om du behöver uppdatera dig om hur allt fungerar och se vilka öppettider som gäller för obemannad returpunkt kan du besöka vår webbplats.</p>' +
          '<a href="https://nodra.se/obemannad-returpunkt" target="_blank">Obemannad returpunkt (nodra.se)</a>' +
          '<p>Glöm inte bort att ladda ner senaste versionen av vår app för in- och utpassering och för att ha sorteringsguiden med dig vid ditt besök!</p>' +
          '<a href="https://nodra.se/sjalvservice-och-kontakt/sjalvservice-ovrigt/nodras-app" target="_blank">Nodras app (nodra.se)</a>',
        nextButtonText: 'Logga ut'
      }
    ]
  },
  getters: {
    getPageGroup1: (state) => (state.pages1 ? state.pages1 : null),
    getPageGroup2: (state) => (state.pages2 ? state.pages2 : null),
    getPageGroup3: (state) => (state.pages3 ? state.pages3 : null),
    getPageGroup4: (state) => (state.pages3 ? state.pages4 : null)
  },
  mutations: {},
  actions: {}
}
